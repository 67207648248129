<template lang="pug">
  b-row.mb-5
    b-col
      router-view(@profile-changed='reloadOrganizationsPanel')
</template>

<script>
export default {
  name: 'manager-organizations-profile',
  methods: {
    reloadOrganizationsPanel() {
      this.$parent.$refs.organizationsPanel.panelReload()
    },
  },
}
</script>
